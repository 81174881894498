<script>
export default {
    data() {
        return {
            openShareM: null,
        };
    },
    methods: {
        loadOpenShareJs(appKey) {
            this.$tools.loadScript("https://os.sdwok.cn/open/os2.js", () => {
                this.initOpenShareJs(appKey);
            });
        },
        initOpenShareJs(appKey) {
            console.log({ appKey });
            this.openShareM = new window.OpenShare({ appKey });
        },
        openShareDownSoft() {
            this.openShareM.wakeupOrInstall;
        },
    },
};
</script>
