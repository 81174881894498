
import http from './http'
export function get(url, params) {
    return new Promise((resolve, reject) => {
        http.get(url, params).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export function post(url, params) {
    return new Promise((resolve, reject) => {
        http.post(url, params).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}