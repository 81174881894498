import Vue from "vue";
Vue.prototype.$tools = {
    uuid() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        var uuid = s.join("");
        return uuid;
    },
    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    },
    changeUrlParam(key, value) {
        let markKey = new URLSearchParams(window.location.search)
        let target = markKey.get(key);
        if (target) {
            markKey.set(key, value)
        } else {
            markKey.append(key, value)
        }
        var originUrl = window.location.origin;
        window.history.pushState(null, null, originUrl + "?" + markKey.toString());
    },
    getCnEncodeUri() {
        const installParams = {};
        let shareName = this.getQueryVariable("shareName");
        if (!shareName) {
            shareName = window.location.origin;
        }
        installParams.shareName = shareName
        let promoteCode = this.getQueryVariable("promoteCode");
        if (promoteCode) {
            installParams.promoteCode = promoteCode
        }
        return installParams;
    },
    loadScript(url, callback) {
        //如果有该JS，则移出该JS
        document.getElementById("") && document.getElementById("").remove();
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.async = "async";
        script.src = url; //该ID放入上方的判断内
        script.id = "";
        var head = document.head || document.getElementsByTagName("head")[0];
        head.appendChild(script); //或者放在body尾部document.body.appendChild(script);
        if (script.readyState) {
            //IE
            script.onreadystatechange = function () {
                if (
                    script.readyState == "complete" ||
                    script.readyState == "loaded"
                ) {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            //非IE
            script.onload = function () {
                callback();
            };
        }
    },
}
export const tools = Vue.prototype.$tools