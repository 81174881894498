<script>
export default {
    data() {
        return {
        };
    },
    methods: {
        loadShareTraceJs(appKey) {
            this.$tools.loadScript("https://res.sharetrace.com/sharetrace.min.js", () => {
                this.initShareTraceJs(appKey);
            });
        },
        initShareTraceJs(appKey) {
            console.log({ appKey });
            window.ShareTrace.init(appKey);
        },
        shareTraceDownSoft() {
            window.ShareTrace.download();
        },
    },
};
</script>
