import md5 from "js-md5";

// export default class signMd5Utils {
//     static getSign(str) {
//         return md5(md5(`${str}9&N4orgck9M!rh2#Wpfyg2Q!teDds8Bl`).toUpperCase()).toUpperCase()
//     }
// }

//以下为上方注释代码混淆后，修改上方代码后再 https://www.jsjiami.com/ 混淆后加入此处
var _0xodv = "jsjiami.com.v6",
  _0x1a25 = [
    _0xodv,
    "w5g+UxgQ",
    "w57CvTzCgwjCocOmYjPCpcOqwrsJwp3DvHvDm2wmB8OZXiDDnAnDkAZFHl5ebA==",
    "w49tLsKEwrrCsWMUKVvCnw==",
    "A8K2wqgCWHRjHlBlAA==",
    "wpl8w6ZZwoA=",
    "ATPCWjsMVDjieadmZJiPIEn.com.v6==",
  ];
(function (_0x219e97, _0x568c34, _0x2ed706) {
  var _0x2a0512 = function (
    _0x4a0d23,
    _0x368232,
    _0x1afc4d,
    _0x1e3228,
    _0x169b2e
  ) {
    (_0x368232 = _0x368232 >> 0x8), (_0x169b2e = "po");
    var _0x12f885 = "shift",
      _0x5bbcab = "push";
    if (_0x368232 < _0x4a0d23) {
      while (--_0x4a0d23) {
        _0x1e3228 = _0x219e97[_0x12f885]();
        if (_0x368232 === _0x4a0d23) {
          _0x368232 = _0x1e3228;
          _0x1afc4d = _0x219e97[_0x169b2e + "p"]();
        } else if (
          _0x368232 &&
          _0x1afc4d["replace"](/[ATPCWMVDedZJPIEn=]/g, "") === _0x368232
        ) {
          _0x219e97[_0x5bbcab](_0x1e3228);
        }
      }
      _0x219e97[_0x5bbcab](_0x219e97[_0x12f885]());
    }
    return 0xb0a36;
  };
  return (_0x2a0512(++_0x568c34, _0x2ed706) >> _0x568c34) ^ _0x2ed706;
})(_0x1a25, 0xdb, 0xdb00);
var _0xe8e0 = function (_0x42f216, _0xfb0d75) {
  _0x42f216 = ~~"0x"["concat"](_0x42f216);
  var _0x21a92d = _0x1a25[_0x42f216];
  if (_0xe8e0["YWSuAP"] === undefined) {
    (function () {
      var _0x57837d =
        typeof window !== "undefined"
          ? window
          : typeof process === "object" &&
            typeof require === "function" &&
            typeof global === "object"
          ? global
          : this;
      var _0x182d3e =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
      _0x57837d["atob"] ||
        (_0x57837d["atob"] = function (_0x560f86) {
          var _0x5a5894 = String(_0x560f86)["replace"](/=+$/, "");
          for (
            var _0x511d12 = 0x0,
              _0x51b816,
              _0x4ed23d,
              _0x3f35d6 = 0x0,
              _0x1293c8 = "";
            (_0x4ed23d = _0x5a5894["charAt"](_0x3f35d6++));
            ~_0x4ed23d &&
            ((_0x51b816 =
              _0x511d12 % 0x4 ? _0x51b816 * 0x40 + _0x4ed23d : _0x4ed23d),
            _0x511d12++ % 0x4)
              ? (_0x1293c8 += String["fromCharCode"](
                  0xff & (_0x51b816 >> ((-0x2 * _0x511d12) & 0x6))
                ))
              : 0x0
          ) {
            _0x4ed23d = _0x182d3e["indexOf"](_0x4ed23d);
          }
          return _0x1293c8;
        });
    })();
    var _0x270980 = function (_0x5d6995, _0xfb0d75) {
      var _0xe549ee = [],
        _0x117a81 = 0x0,
        _0x18da13,
        _0x119b9b = "",
        _0x2c4987 = "";
      _0x5d6995 = atob(_0x5d6995);
      for (
        var _0x46d12d = 0x0, _0x4761b5 = _0x5d6995["length"];
        _0x46d12d < _0x4761b5;
        _0x46d12d++
      ) {
        _0x2c4987 +=
          "%" +
          ("00" + _0x5d6995["charCodeAt"](_0x46d12d)["toString"](0x10))[
            "slice"
          ](-0x2);
      }
      _0x5d6995 = decodeURIComponent(_0x2c4987);
      for (var _0x11f0b9 = 0x0; _0x11f0b9 < 0x100; _0x11f0b9++) {
        _0xe549ee[_0x11f0b9] = _0x11f0b9;
      }
      for (_0x11f0b9 = 0x0; _0x11f0b9 < 0x100; _0x11f0b9++) {
        _0x117a81 =
          (_0x117a81 +
            _0xe549ee[_0x11f0b9] +
            _0xfb0d75["charCodeAt"](_0x11f0b9 % _0xfb0d75["length"])) %
          0x100;
        _0x18da13 = _0xe549ee[_0x11f0b9];
        _0xe549ee[_0x11f0b9] = _0xe549ee[_0x117a81];
        _0xe549ee[_0x117a81] = _0x18da13;
      }
      _0x11f0b9 = 0x0;
      _0x117a81 = 0x0;
      for (var _0x424775 = 0x0; _0x424775 < _0x5d6995["length"]; _0x424775++) {
        _0x11f0b9 = (_0x11f0b9 + 0x1) % 0x100;
        _0x117a81 = (_0x117a81 + _0xe549ee[_0x11f0b9]) % 0x100;
        _0x18da13 = _0xe549ee[_0x11f0b9];
        _0xe549ee[_0x11f0b9] = _0xe549ee[_0x117a81];
        _0xe549ee[_0x117a81] = _0x18da13;
        _0x119b9b += String["fromCharCode"](
          _0x5d6995["charCodeAt"](_0x424775) ^
            _0xe549ee[(_0xe549ee[_0x11f0b9] + _0xe549ee[_0x117a81]) % 0x100]
        );
      }
      return _0x119b9b;
    };
    _0xe8e0["QHJrvH"] = _0x270980;
    _0xe8e0["rXpCFc"] = {};
    _0xe8e0["YWSuAP"] = !![];
  }
  var _0x5c754b = _0xe8e0["rXpCFc"][_0x42f216];
  if (_0x5c754b === undefined) {
    if (_0xe8e0["HDuBZa"] === undefined) {
      _0xe8e0["HDuBZa"] = !![];
    }
    _0x21a92d = _0xe8e0["QHJrvH"](_0x21a92d, _0xfb0d75);
    _0xe8e0["rXpCFc"][_0x42f216] = _0x21a92d;
  } else {
    _0x21a92d = _0x5c754b;
  }
  return _0x21a92d;
};
export default class signMd5Utils {
  static ["getSign"](_0x34ee9b) {
    var _0xf05773 = {
      vMmiH: function (_0x71457e, _0x5452d5) {
        return _0x71457e(_0x5452d5);
      },
      CBnUz: function (_0x2ee7aa, _0x4d4609) {
        return _0x2ee7aa(_0x4d4609);
      },
    };
    return _0xf05773[_0xe8e0("0", "X88$")](
      md5,
      _0xf05773[_0xe8e0("1", "Q0bL")](md5, _0x34ee9b + _0xe8e0("2", "PIM6"))[
        _0xe8e0("3", "IFyO")
      ]()
    )[_0xe8e0("4", "ex&w")]();
  }
}
_0xodv = "jsjiami.com.v6";
