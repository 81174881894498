import axios from 'axios';
import {tools} from '@/assets/js/tools'
import signMd5Utils from '@/assets/js/signMd5Utils'

let http = axios.create({
  timeout: 5000
})

//请求拦截
http.interceptors.request.use(
  config => {
      const uuid = tools.uuid()
      const sign = signMd5Utils.getSign(uuid)
      config.headers["x-auth-uu"] = uuid
      config.headers["x-auth-sign"] = sign
    return config;
  },
  error => {
    Promise.reject(error);
  }
)

//响应拦截
http.interceptors.response.use(
  response => {
    let res = response.data;
    if (res.code == "12200") {//请求成功时
      return Promise.resolve(res);
    } else {
      //各种请求状态的处理
    }
  },
  error => {
    return Promise.reject(error);
  }
)

export default http;