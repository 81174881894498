import openShareMixins from "@c/mixins/openShare.mixins";
import openInstallMixins from "@c/mixins/openInstall.mixins";
import shareTraceMixins from "@c/mixins/shareTrace.mixins.";
import urijs from "urijs";
import { get } from "lodash-es";
export default {
    mixins: [openShareMixins, openInstallMixins,shareTraceMixins],
    methods: {
        jumpToLandingUrl() {
            const replaceUrl = get(
                this.$store.state,
                "webUrls.web_landing_page_domain1",
                ""
            );
            const serach = window.location.search;
            window.location.href = `${replaceUrl}${serach}`;
        },
        addShareNameToUrl (){
            //添加默认sharename
            const currUrl = urijs(window.location.href);
            if(currUrl.hasSearch('shareName')) return;

            const installParams = this.$tools.getCnEncodeUri();
            currUrl.addSearch('shareName',installParams.shareName)
            window.location.href = currUrl.href()
        },
        openShareUrl(url) {
            const domainCompleteUri = urijs(url);
            const installParams = this.$tools.getCnEncodeUri();
            domainCompleteUri.addSearch("installParams", JSON.stringify(installParams));
            window.open(domainCompleteUri, "_blank”");
        },
        initInstallSdkJs(appkey,appShareSdkPlatform) {
            console.log(appShareSdkPlatform)
            switch (appShareSdkPlatform) {
                case "openInstall":
                    this.loadOpenInstallJs(appkey)
                    break;
                case "shareTrace":
                    this.loadShareTraceJs(appkey)
                    break;
                default:
                    this.loadOpenShareJs(appkey);
                    break;
            }
        },
        wakeupOrInstall(appShareSdkPlatform){
            console.log(appShareSdkPlatform)
            switch (appShareSdkPlatform) {
                case "openInstall":
                    this.openInstallDownSoft()
                    break;
                case "shareTrace":
                    this.shareTraceDownSoft()
                    break;
                default:
                    this.openShareDownSoft();
                    break;
            }
        }
    }
}